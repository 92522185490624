import React from "react";
import { useTranslation } from "react-i18next";

// Config. ---------------------------------------------------------------------
import { availableDirection, defaultLanguage } from "config/i18n";

// Utils. ----------------------------------------------------------------------
import { i18nGetLanguage } from "utils/i18n/i18nGetLanguage";

// =============================================================================
// useHookLanguage...
// =============================================================================
const useHookLanguage = () => {
  // Members. ------------------------------------------------------------------
  const { i18n } = useTranslation();

  // Set language according to user locale.-----------------------------------
  React.useEffect(() => {
    if (Object.keys(availableDirection).indexOf(i18nGetLanguage()) !== -1) {
      document.body.dir = availableDirection[i18nGetLanguage()];
      i18n.changeLanguage(i18nGetLanguage());
    } else {
      document.body.dir = availableDirection[defaultLanguage];
      i18n.changeLanguage(defaultLanguage);
    }
  }, [i18n]);
};
export default useHookLanguage;
