import Axios, { AxiosResponse } from "axios";

// Docaposte agility. -------------------------------------------------------------
import { KEYCLOAK_TOKEN_KEY_IN_LOCALSTORAGE } from "@docaposte-agility/da-react-keycloak";

// =============================================================================
// executeRequestInterceptor...
// =============================================================================
export const executeRequestInterceptor = () => {
  Axios.interceptors.request.use(
    function (config) {
      const token = window.localStorage.getItem(
        KEYCLOAK_TOKEN_KEY_IN_LOCALSTORAGE,
      );
      config.headers.Authorization = `Bearer ${token}`;
      config.baseURL = String(window.__ENV__.REACT_APP_BO_BASE_URL);
      return {
        ...config,
      };
    },
    function (error) {
      return Promise.reject(error);
    },
  );
};

// =============================================================================
// executeResponseInterceptor...
// =============================================================================
export const executeResponseInterceptor = () => {
  Axios.interceptors.response.use((response: AxiosResponse) => {
    return response;
  });
};
