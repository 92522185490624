import React from "react";
import { I18nextProvider } from "react-i18next";

// Configs. --------------------------------------------------------------------
import i18n from "config/i18n";

// Contexts. -------------------------------------------------------------------
import LoadingProvider from "app/context/LoadingProvider";
import ToasterProvider from "app/context/ToasterProvider";
import ContextProvider from "app/context/Context";

// Hooks. ----------------------------------------------------------------------
import useHookLanguage from "hooks/useLanguage.hook";

// Services. -------------------------------------------------------------------
import { useGetBackendVersion } from "services/keycloak/useGetBackendVersion.hook";

// Utils. ----------------------------------------------------------------------
import { dateLog } from "utils/date/dateLog";
import { dateFormat } from "utils/date/dateFormat";
import { getPackageName } from "utils/package/getPackageName";

// =============================================================================
// IdpProvider...
// =============================================================================
const IdpProvider = ({ children }) => {
  // Hooks. --------------------------------------------------------------------
  React.useEffect(() => {
    getBackendVersion({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Backend. ------------------------------------------------------------------
  const { mutate: getBackendVersion } = useGetBackendVersion({
    onSuccess: (data) => {
      console.debug(dateLog("BKO", "useGetBackendVersion.onSuccess"), data);
      const resData = data["data"];
      console.log(
        "[DA] " +
          dateFormat(new Date().toISOString(), "yyyy-MM-dd hh:mm:ss") +
          " " +
          getPackageName().replace("front", "back") +
          " - V" +
          resData["version"] +
          (!resData["sha"] ? "" : "." + resData["sha"]),
      );
    },
  });

  // =============================================================================
  // LanguageContext...
  // =============================================================================
  const LanguageContext = ({ children }) => {
    useHookLanguage();
    return children;
  };

  // Render. -------------------------------------------------------------------
  return (
    <LoadingProvider>
      <ToasterProvider>
        <ContextProvider>
          <I18nextProvider i18n={i18n}>
            <LanguageContext>{children}</LanguageContext>
          </I18nextProvider>
        </ContextProvider>
      </ToasterProvider>
    </LoadingProvider>
  );
};
export default IdpProvider;
