import React from "react";
// Docaposte agility. ----------------------------------------------------------
import { KeycloakProvider } from "@docaposte-agility/da-react-keycloak";

// Pages. ----------------------------------------------------------------------
import ContactAdministrator from "app/pages/ContactAdministrator";

// Components. -----------------------------------------------------------------
import Backdrop from "app/components/common/Backdrop";

// Utils. ----------------------------------------------------------------------
import { dateLog } from "utils/date/dateLog";
import { getStorageValue, setStorageValue } from "utils/memoryStorage";

// Services. -------------------------------------------------------------------
import { useGetKeycloakConfigs } from "services/keycloak/useGetKeycloakConfigs.hook";

// =============================================================================
// LoginProvider...
// =============================================================================
const LoginProvider = ({ children }) => {
  // Members. ------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [keycloakConfigErreur, setKeycloakConfigErreur] = React.useState(false);
  const searchParams = new URLSearchParams(document.location.search);
  const idpHint = searchParams.get("idp") ? searchParams.get("idp") : "";

  // Hooks. --------------------------------------------------------------------
  React.useEffect(() => {
    setLoading(true);
    getKeycloakConfigs({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Backend. ------------------------------------------------------------------
  const { mutate: getKeycloakConfigs } = useGetKeycloakConfigs({
    onSuccess: (data) => {
      console.debug(dateLog("BKO", "useGetKeycloakConfig.onSuccess"), data);
      const resData = data["data"];
      setStorageValue("tenant", resData?.realm || "");
      window.__ENV__.KEYCLOAK_CONFIGS = resData ? [resData] : [];
      setLoading(false);
    },
    onError: (error) => {
      console.debug(dateLog("BKO", "useGetKeycloakConfigs.onError"), error);
      setStorageValue("tenant");
      setStorageValue("connected");
      setStorageValue("route");
      setStorageValue("breadcrumbs");
      setLoading(false);
      setKeycloakConfigErreur(true);
    },
  });

  // functions. ----------------------------------------------------------------
  const getKeycloak = () => {
    const configs: Keycloak.KeycloakConfig[] = window.__ENV__
      .KEYCLOAK_CONFIGS as Keycloak.KeycloakConfig[];
    if (configs !== undefined) {
      const filtered = configs.filter(
        (value) => value.realm === getStorageValue("tenant"),
      );
      if (filtered.length > 0) {
        return filtered[0];
      }
    }
    return undefined;
  };

  // Render. -------------------------------------------------------------------
  if (window.__ENV__.KEYCLOAK_CONFIGS?.length === 0 && !keycloakConfigErreur) {
    return <Backdrop loading={loading} />;
  }
  if (getStorageValue("tenant") !== null && getKeycloak() !== undefined) {
    return (
      <KeycloakProvider
        authClient={getKeycloak()}
        onEvent={(event) => {
          console.debug(dateLog("KCK", "onKeycloakEvent"), event);
        }}
        onTokens={(tokens) => {
          console.debug(dateLog("KCK", "onKeycloakTokens"), tokens);
        }}
        onError={(event) => {
          console.debug(dateLog("KCK", "onKeycloakError"), event);
        }}
        idpHint={idpHint?.toString()}
      >
        {children}
      </KeycloakProvider>
    );
  }
  return (
    <ContactAdministrator
      type="contactAdmin"
      mobileImg="images/actionConnect.png"
    />
  );
};
export default LoginProvider;
